import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div class="text-center py-10 md:py-40">
      	<h1 class="text-xl md:text-6xl text-gray-500 font-bold">404: Not Found</h1>
      	<p class="py-5 mb-0">You just hit a route that doesn't exist</p>
		<p>Go <a className="text-purple-500" href="/">Home</a> to start over.</p>
	</div>
  </Layout>
)

export default NotFoundPage
